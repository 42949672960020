<template>
  <MonthlyAggregatedPriceChart
    description-translation-key="order.OrderPurchasesChart.title"
    :product-id="productId"
    :title="$t('order.OrderPurchasesChart.purchases')"
    :y-axis-key="'sumPrice'"
    :chart-options="{
      hoverBackgroundColor: colors.primary,
      backgroundColor: '#5E81E4',
      barThickness: 6.65,
    }"
    :initial-data="data"
    :initial-loading="loading"
    :supplier-id="supplierId"
  >
    <template #chart="{ chartData, height, min, max }">
      <FloatingRoundedBarChart :chart-data="chartData" :height="height" :min="min" :max="max" />
    </template>
  </MonthlyAggregatedPriceChart>
</template>
<script>
import FloatingRoundedBarChart from '@/modules/purchase-management/components/supplierPurchases/components/FloatingRoundedBarChart';
import MonthlyAggregatedPriceChart from './MonthlyAggregatedPriceChart';
import colors from '@/stylesheets/scss/colors.module.scss';

export default {
  components: { FloatingRoundedBarChart, MonthlyAggregatedPriceChart },
  props: {
    productId: { type: String, required: true },
    supplierId: { type: String, required: true },
    data: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  setup() {
    return {
      colors,
    };
  },
};
</script>
<style scoped lang="scss"></style>
