var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MonthlyAggregatedPriceChart',{attrs:{"description-translation-key":"order.AveragePriceChart.title","product-id":_vm.productId,"title":_vm.$t('order.AveragePriceChart.averagePrice'),"y-axis-key":'pricedOrderItemAverage',"chart-options":{
    hoverBackgroundColor: _vm.colors.primary,
    hoverBorderColor: _vm.colors.primary,
    backgroundColor: '#5E81E4',
    borderColor: '#5E81E4',
    fill: false,
  },"initial-data":_vm.data,"initial-loading":_vm.loading,"supplier-id":_vm.supplierId},scopedSlots:_vm._u([{key:"chart",fn:function({ chartData, height, min, max }){return [_c('FloatingLineChart',{attrs:{"chart-data":chartData,"height":height,"min":min,"max":max}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }