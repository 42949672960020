<template>
  <MonthlyAggregatedPriceChart
    description-translation-key="order.AveragePriceChart.title"
    :product-id="productId"
    :title="$t('order.AveragePriceChart.averagePrice')"
    :y-axis-key="'pricedOrderItemAverage'"
    :chart-options="{
      hoverBackgroundColor: colors.primary,
      hoverBorderColor: colors.primary,
      backgroundColor: '#5E81E4',
      borderColor: '#5E81E4',
      fill: false,
    }"
    :initial-data="data"
    :initial-loading="loading"
    :supplier-id="supplierId"
  >
    <template #chart="{ chartData, height, min, max }">
      <FloatingLineChart :chart-data="chartData" :height="height" :min="min" :max="max" />
    </template>
  </MonthlyAggregatedPriceChart>
</template>
<script>
import FloatingLineChart from '@/modules/purchase-management/components/supplierPurchases/components/FloatingLineChart';
import MonthlyAggregatedPriceChart from './MonthlyAggregatedPriceChart';
import colors from '@/stylesheets/scss/colors.module.scss';

export default {
  components: { FloatingLineChart, MonthlyAggregatedPriceChart },
  props: {
    productId: { type: String, required: true },
    supplierId: { type: String, required: true },
    data: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  setup() {
    return {
      colors,
    };
  },
};
</script>
<style scoped lang="scss"></style>
