<template>
  <div v-loading="loading" class="card shadow">
    <div class="card-header">
      <div class="fw-bold">{{ title }}</div>
      <div>
        {{ $t(descriptionTranslationKey, { year: formattedYear, price: formattedHeaderPrice }) }}
      </div>
      <div class="period-button-container">
        <el-button type="text" icon="el-icon-arrow-left" @click="prev" />
        <span :dir="$direction" class="period-text">{{ buttonText }}</span>
        <el-button type="text" icon="el-icon-arrow-right" :disabled="nextDisabled" @click="next" />
      </div>
    </div>
    <div class="card-body">
      <slot name="chart" :chart-data="chartData" :height="132" :min="fromDate.toJSDate()" :max="toDate.toJSDate()" />
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { computed, ref, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';

import { useOrderItemAggregationByMonthWithIntegral } from '../compositions/orderItemAggregationsByMonthWithIntegral';

import { formatCurrencyOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  props: {
    productId: { type: String, required: true },
    supplierId: { type: String, required: true },
    title: { type: String, required: true },
    descriptionTranslationKey: { type: String, required: true },
    yAxisKey: { type: String, required: true },
    chartOptions: { type: Object, default: () => {} },
    initialData: { type: Array, required: true },
    initialLoading: { type: Boolean, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { currencyFormat } = useCurrency();
    const toDate = ref(DateTime.local().endOf('year').startOf('month'));
    const useInitialData = ref(true);
    const fromDate = computed(() => toDate.value.startOf('year'));

    const nextDisabled = computed(() => toDate.value > DateTime.local());

    const next = () => {
      toDate.value = toDate.value.plus({ years: 1 });
      useInitialData.value = false;
    };
    const prev = () => {
      toDate.value = toDate.value.minus({ years: 1 });
      useInitialData.value = false;
    };

    const buttonText = computed(() => {
      if (toDate.value > DateTime.local()) return root.$i18n.t('order.OrderPurchasesChart.currentYear');
      const [fromDateFormat, toDateFormat] = [fromDate, toDate].map((date) =>
        date.value.toJSDate().toLocaleDateString(root.$i18n.locale, {
          month: '2-digit',
          year: '2-digit',
        })
      );
      return root.$i18n.t('direction') === 'ltr'
        ? `${fromDateFormat} - ${toDateFormat}`
        : `${toDateFormat} - ${fromDateFormat}`;
    });

    const { aggregations, loading: aggregationLoading } = useOrderItemAggregationByMonthWithIntegral(
      computed(() => ({
        tenantId: currentTenant.value.id,
        productId: props.productId,
        fromDate: fromDate.value.toFormat('yyyy-MM-dd'),
        toDate: toDate.value.toFormat('yyyy-MM-dd'),
        supplierId: props.supplierId,
        retrieveSpecific: useInitialData.value,
      }))
    );

    const data = computed(() => (useInitialData.value ? props.initialData : aggregations.value));
    const loading = computed(() => (useInitialData.value ? props.initialLoading : aggregationLoading.value));

    const formattedHeaderPrice = computed(() => {
      let numericalAmmount = 0;
      if (!data.value) return '-';

      if (props.yAxisKey === 'pricedOrderItemAverage') {
        const totalAmount = data.value.reduce((total, { sumPrice }) => total + sumPrice, 0);
        const totalQuantity = data.value.reduce((total, { quantityWithPrice }) => total + quantityWithPrice, 0);
        numericalAmmount = totalAmount / totalQuantity;
      } else {
        numericalAmmount = data.value.reduce((total, orderItem) => total + orderItem[props.yAxisKey], 0);
      }

      return formatCurrencyOrDash(numericalAmmount, currencyFormat.value);
    });

    const formattedYear = computed(() =>
      fromDate.value.toJSDate().toLocaleDateString(root.$i18n.locale, { year: 'numeric' })
    );

    const xAxisDates = computed(() => {
      let startDate = fromDate.value;

      const dates = [];
      while (startDate <= toDate.value) {
        dates.push(startDate);
        startDate = startDate.plus({ months: 1 });
      }
      return dates;
    });

    const chartData = computed(() => ({
      datasets: [
        {
          data: xAxisDates.value.map((date) => {
            const monthData = data.value.find(({ groupedByMonth }) => groupedByMonth === date.toFormat('MM-yy'));
            return {
              x: date.toJSDate(),
              y: monthData ? monthData[props.yAxisKey] : null,
              metadata: {
                quantity: monthData?.quantityWithPrice ?? 0,
              },
            };
          }),
          ...props.chartOptions,
        },
      ],
    }));

    return {
      toDate,
      fromDate,
      next,
      prev,
      nextDisabled,
      buttonText,
      chartData,
      loading,
      formattedYear,
      formattedHeaderPrice,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .period-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $outline;
    border-radius: 3px;
    padding: 0.5rem 0.625rem;
    height: 2rem;
    min-width: 8.5rem;

    ::v-deep .el-button {
      padding: 0;
      border: none;

      &:enabled {
        color: #1f284d;
      }
    }

    .period-text {
      font-size: 12px;
      padding: 0 0.625rem;
    }
  }
}
</style>
