<template>
  <div class="card shadow">
    <div class="card-header fw-bold">
      {{ $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.header') }}
    </div>
    <div class="card-body">
      <el-slider
        v-model="sliderPercent"
        :disabled="!basePrice"
        :min="minimum"
        :max="maximum"
        :marks="marks"
        :format-tooltip="formatPercent"
        class="pb-5 mx-2"
      />
      <div class="d-flex align-items-end justify-content-between mt-7">
        <div class="d-flex flex-column align-items-center w-25">
          <InputMoneyShekels v-model="targetPrice" :disabled="!basePrice" />
          <small class="text-muted">{{
            $t('terms.supplierTerms.purchaseHistoryTop20.tableHeaders.targetPrice')
          }}</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <h3 class="mb-1">{{ formatPercent(percentOfChange) }}</h3>
          <small class="text-muted">{{
            percentOfChange > 0
              ? $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.priceCreep')
              : $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.priceReduction')
          }}</small>
        </div>
        <div class="d-flex flex-column align-items-center">
          <div class="d-flex align-items-center">
            <h3 class="mb-1">{{ formatMoney(differeneces) }}</h3>
            <template v-if="percentOfChange !== 0">
              <FullArrowUpIcon v-if="percentOfChange > 0" class="text-danger" />
              <FullArrowDownIcon v-else class="text-success" />
            </template>
          </div>
          <small class="text-muted">
            {{
              percentOfChange > 0
                ? $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.estimatedAnnualAdditionalExpense')
                : $t('terms.supplierTerms.purchaseHistoryTop20.annualImpactSlider.estimatedAnnualSaving')
            }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { isNil } from 'ramda';

import { useCurrency } from '@/locale/useCurrency';
import {
  formatCurrencyOrDash,
  formatPercentWith2FractionsOrDash as formatPercent,
} from '@/modules/purchase-management/purchaseManagementFormatters';

import { FullArrowUpIcon, FullArrowDownIcon } from '@/assets/icons';
import { InputMoneyShekels } from '@/modules/core';

export default {
  components: { FullArrowUpIcon, FullArrowDownIcon, InputMoneyShekels },
  props: {
    basePrice: { type: Number, default: 0 },
    monthlyAverageQuantity: { type: Number, default: 0 },
    minimum: { type: Number, default: -100 },
    maximum: { type: Number, default: 100 },
    showMarks: { type: Boolean, default: false },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const targetPrice = ref(props.basePrice);

    const { currencyFormat: baseCurrencyFormat } = useCurrency();

    const currencyFormat = computed(() => ({ ...baseCurrencyFormat.value, maximumFractionDigits: 0 }));

    const percentOfChange = computed(() => {
      if (!props.basePrice || isNil(targetPrice.value)) return 0;
      return ((targetPrice.value - props.basePrice) / props.basePrice) * 100;
    });

    const sliderPercent = computed({
      get() {
        return Math.max(-50, Math.min(50, percentOfChange.value));
      },
      set(value) {
        if (value === sliderPercent.value) return;
        if (value === 0) targetPrice.value = props.basePrice;
        else targetPrice.value = Math.round((props.basePrice * (100 + value)) / 100);
      },
    });

    const formatMoney = (number) => formatCurrencyOrDash(number, currencyFormat.value);

    const buildMark = (percent) => {
      return {
        style: { 'min-width': '2.5rem' },
        label: root.$createElement('div', { class: 'd-flex align-items-center flex-column' }, [
          root.$createElement('small', { class: 'text-typography-primary' }, formatPercent(percent)),
          root.$createElement('small', { class: 'text-muted' }, [
            formatMoney(props.basePrice + props.basePrice * (percent / 100)),
          ]),
        ]),
      };
    };

    const marks = computed(() => {
      if (!props.showMarks) return {};
      return {
        [props.minimum]: buildMark(props.minimum),
        [props.minimum / 2]: buildMark(props.minimum / 2),
        0: buildMark(0),
        [props.maximum / 2]: buildMark(props.maximum / 2),
        [props.maximum]: buildMark(props.maximum),
      };
    });

    const differeneces = computed(() => {
      if (isNil(targetPrice.value)) return 0;
      return (targetPrice.value - props.basePrice) * props.monthlyAverageQuantity * 12;
    });

    watch(
      () => props.basePrice,
      (updatedValue) => {
        targetPrice.value = updatedValue;
      },
      { immediate: true }
    );

    return {
      targetPrice,
      percentOfChange,
      sliderPercent,
      marks,
      differeneces,
      formatMoney,
      formatPercent,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .el-slider__bar {
  background-color: transparent;
}

::v-deep .el-slider__button {
  border: 3px solid $white;
  background-color: $primary;
}
</style>
